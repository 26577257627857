import { getAppCompanies, getCompanyMeta, getDefaultOperatingUnit } from "@farmact/customer-portal-api-client";
import { AppCompany, createDefaultAppCompanySettings } from "@farmact/model/src/model/AppCompany";
import { Variant } from "@farmact/model/src/model/CompanyMeta";
import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import { useCustomClaims } from "@/components/authentication/useCustomClaims";
import { useStableIdArray } from "@/util/customHooks/useStableArray";

export function useContractorContextData(authUser: User | null | undefined) {
    const [customClaims] = useCustomClaims(authUser);

    const customerIds = customClaims?.customerIds;
    const companyIds: string[] = customerIds ? Object.keys(customerIds) : [];

    const [contractors, setContractors] = useState<ContractorData[]>([]);
    const [appCompanies, setAppCompanies] = useState<Pick<AppCompany, "id" | "settings">[]>([]);
    const [loading, setLoading] = useState(false);
    const stableCompanyIdArray = useStableIdArray(companyIds);

    useEffect(() => {
        const getUnits = async () => {
            setLoading(true);
            const units: ContractorData[] = [];
            for (const companyId of stableCompanyIdArray) {
                const operatingUnitData = await getDefaultOperatingUnit({
                    companyId,
                });
                const companyVariant = await getCompanyMeta({ companyId });
                units.push({
                    companyId,
                    variant: companyVariant.variant as Variant,
                    ...operatingUnitData,
                });
            }
            setContractors(units);
            setLoading(false);
        };
        getUnits();
    }, [stableCompanyIdArray]);

    useEffect(() => {
        const fetchGetCanRequestOrders = async () => {
            const appCompanies = await getAppCompanies();
            setAppCompanies(
                appCompanies.map(appCompany => ({
                    id: appCompany.id,
                    settings: {
                        ...createDefaultAppCompanySettings(Variant.AGRICULTURAL_CONTRACTOR),
                        enableOrderRequests: appCompany.settings.enableOrderRequests as boolean,
                    },
                }))
            );
        };
        if (stableCompanyIdArray.length > 0) {
            fetchGetCanRequestOrders();
        }
    }, [stableCompanyIdArray]);

    return { contractors, appCompanies, loading };
}

export type ContractorData = {
    companyId: string;
    name: string;
    city: string;
    zip: string;
    street: string;
    email: string;
    website: string;
    variant: Variant;
    // enableOrderRequests: AppCompany["settings"]["enableOrderRequests"];
};
