import { Context, createContext } from "react";
import { ContractorContextType } from "./useContractorsContext";

const ContractorContext: Context<ContractorContextType> = createContext<ContractorContextType>({
    contractors: [],
    appCompanies: [],
    loading: false,
});

export default ContractorContext;
