"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionSimulatorService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class TransactionSimulatorService {
    /**
     * 🔭 Simulate a bank transaction and trigger the matching process.
     * @returns any
     * @throws ApiError
     */
    static createTransaction({ transactionAmount, remittanceInformation, companyId, operatingUnitId, valueDate = '2024-11-13', bookingDate = '2024-11-13', }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/transaction-simulator',
            query: {
                'valueDate': valueDate,
                'bookingDate': bookingDate,
                'transactionAmount': transactionAmount,
                'remittanceInformation': remittanceInformation,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
}
exports.TransactionSimulatorService = TransactionSimulatorService;
